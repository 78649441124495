import * as React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text"

// types
import { ProjectType } from "../../types";

// components
import {
	Anchor,
	Badge,
	Center,
	Container,
	Grid,
	Group,
	MantineTheme,
	Stack,
	Text,
	Title,
	createStyles,
	useMantineTheme,
} from "@mantine/core";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderQminoRichText } from "../../util/rich-text";

interface Props {
	project: ProjectType;
}

const useStyles = createStyles((theme: MantineTheme) => ({
	icon: {
		[theme.fn.smallerThan('md')]: {
			fontSize: "calc(24px + 1vw)",
			display: 'flex',
			justifyContent:'flex-start',
			paddingLeft: 50,
			paddingRight: 50,
			marginBottom: 30
		},
	},

	projectBadge: {
		[theme.fn.smallerThan('md')]: {
			alignSelf: "flex-start",
			fontSize: "calc(16px + 1vw)",
			padding:theme.spacing.lg,
			marginTop: theme.spacing.lg
		}
	},

	projectTitle: {
		[theme.fn.smallerThan('md')]:{
			fontSize: "calc(32px + 1vw)",
			textAlign:'left',
			lineHeight:1.2,
			paddingTop: theme.spacing.md
		}
	},

	projectDesc: {
		[theme.fn.smallerThan('md')]:{
			fontSize: "calc(30px + 1vw)",
			textAlign:'left',
			lineHeight:1.5,
			paddingTop: theme.spacing.md
		}
	},

	projectFont: {
		[theme.fn.smallerThan('md')]:{
			fontSize: "calc(24px + 1vw)",
			textAlign:'center',
			marginBottom: '0 !important',
			paddingBottom:'0 !important',
		}
	},

	imageSize: {
		[theme.fn.smallerThan('md')]:{
			transform:'scale(1.7)',
			marginTop: 75,
			padding: theme.spacing.lg,
			marginBottom: 75
		}
	},

	logoSize: {
		[theme.fn.smallerThan('md')]:{
			transform:'scale(1.8)',
			marginTop: 50,
			marginBottom: 40
		}
	},

	textClass:{
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(36px + 1vw)",
            lineHeight:1.3,
			textAlign:'justify',
            paddingRight: theme.spacing.xl,
            paddingLeft: theme.spacing.xl,
			marginTop: theme.spacing.xl
        }
    },

	anchorClass:{
		[theme.fn.smallerThan('md')]:{
			lineHeight:1.3,
			fontSize: "calc(36px + 1vw)",
		}
	}
}))

const ProjectContent = ({ project }: Props) => {
	const { classes } = useStyles();
	const theme: MantineTheme = useMantineTheme();

	const ContentDetail = () => {
		const logo = getImage(project.client?.logo?.gatsbyImageData);

		return (
			<Container pt="5rem" size="lg">
				<Grid>
					{/* Applied GridCol properties to make it responsive and center aligned */}
					<Grid.Col offsetMd={0} offset={5} span={2}>
						<Container fluid>
						<Center className={classes.logoSize} mt="md">{logo && <GatsbyImage image={logo!} alt="" />}</Center>
						</Container>
					</Grid.Col>
					<Grid.Col offsetMd={0} offset={3} offsetSm={1} md={7} sm={10} span={7}>
						<Text className={classes.projectFont}>
						{renderQminoRichText(project?.description!,classes.textClass,classes.anchorClass)}
						</Text>
						</Grid.Col>
				</Grid>
			</Container>
		);
	};

	return (
		<>
			<div
				style={{
					backgroundColor: theme.colors.gray[1],
				}}
			>
				<Container pt="5rem" size="lg" >
					<Anchor component={Link} to={"/projecten"}>
						<Group spacing={10} py="lg" className={classes.icon}>

							<FaChevronLeft size={12} />
							Terug naar projecten

						</Group>
					</Anchor>
					<Grid>
						<Grid.Col offsetMd={0} offset={3} offsetSm={0.8} md={6} sm={7} span={5}>
							<Stack align="flex-start">
								<Badge color={project.tags[0] == "Consultancy" ? "brand.8" : "pink.8"} radius="sm"
									className={classes.projectBadge}
									sx={(theme) => ({
										backgroundColor: project.tags[0] == 'Consultancy' ? theme.colors.brand[2] : theme.colors.pink[2],
									})}>
									{project.tags[0]}
								</Badge>
								<Title size="h2">
									<Text color="gray.8" weight={700} className={classes.projectTitle}>
										{project.title}
									</Text>
								</Title>
								<Text color="gray.6" weight={500} className={classes.projectTitle}>
									In opdracht van {project.client.name}
								</Text>
								<Text color="gray.7" weight={500} mb={17} className={classes.projectDesc}>
									{project.summary}
								</Text>
							</Stack>
						</Grid.Col>
						{/* Applied GridCol properties to make it responsive and center aligned */}
						<Grid.Col offsetMd={0} offset={3} offsetSm={2.8} offsetLg={0} xs={12} sm={6} span={7} style={{ position: "relative", top: "30px"}}>
							<Center className={classes.imageSize}>
								{project.headerPicture &&
									<GatsbyImage image={getImage(project.headerPicture.gatsbyImageData)!} alt="" />
								}
							</Center>
						</Grid.Col>
					</Grid>
				</Container>
			</div>
			<ContentDetail />
		</>
	);
};

export default ProjectContent;
