import * as React from "react";
import { TbSend } from "react-icons/tb";
import { Container, Text, Title, Stack, Button, Space, SimpleGrid, MantineTheme, useMantineTheme, createStyles } from "@mantine/core";

// illustrations
import projectIdeaSVG from '../../images/illustrations/project-idea.svg'

const useStyles = createStyles((theme: MantineTheme) => ({
	container: {
		maxWidth: 1245,
		[theme.fn.smallerThan('md')]: {
			textAlign: 'center',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			maxHeight: 768
		},
	},
	contentAlign: {
		[theme.fn.smallerThan('md')]: {
			alignItems: 'center',
		}
	},
	textFont: {
		lineHeight: 1.6,
		[theme.fn.smallerThan('md')]: {
			fontSize: "calc(32px + 1vw)"
		}
	},
	buttonMd: {
		[theme.fn.smallerThan('md')]: {
			padding: 20,
			fontSize: "calc(32px + 1vw)",
			minHeight: 80,
            height: 80,
		}
	}
}))

const ProjectenIdee = () => {
	const { classes } = useStyles();
	const theme: MantineTheme = useMantineTheme();
	return (
		<div
			style={{
				backgroundColor: theme.colors.gray[1],

			}}
		>
			<Container py="2rem" size="sm" className={classes.container}>
				<SimpleGrid cols={2}
					breakpoints={[
						{ maxWidth: 'md', cols: 1, spacing: 'lg', }, // added breakpoints to align text
					]}
				>

					{/* Left grid starts here */}
					<div>
						<Stack align="flex-start" className={classes.contentAlign}>
							<Space h="xl" />
							<Title order={3} size="h3">
								<Text className={classes.textFont}>Project idee?</Text>
							</Title>
							<Text color="gray.7" weight={500} mb={17} sx={{ lineHeight: 1.6 }}
								className={classes.textFont}>
								Wij staan voor u klaar.
							</Text>
							<Button leftIcon={<TbSend size={20} />} mt="sm" component="a" href="mailto:hello@qmino.com"
								className={classes.buttonMd}>
								Contacteer ons
							</Button>
						</Stack>
					</div>
					{/* Left grid ends here */}
					{/* Right grid starts here */}
					<div>
						<img src={projectIdeaSVG} alt={"Project Idea"} style={{ top: 30 }} />
					</div>
					{/* Right grid ends here */}
				</SimpleGrid>
			</Container>
		</div>
	);
};

export default ProjectenIdee;
