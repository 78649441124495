import * as React from "react";
import {HeadProps, PageProps} from "gatsby";

// components
import Layout from "../components/layout";
import { ProjectType } from "../types";
import { ClientType } from "../types/index";
import OtherProjects from "../components/projecten-page/other-projects";
import ProjectenIdee from "../components/projecten-page/projecten-idee";
import ProjectContent from "../components/projecten-page/project-content";
import SEO from "../components/seo";
import Projecten from "../pages/projecten";

type PageContext = {
	previous: {
		slug: string;
		title: string;
		client: ClientType;
	};
	next: {
		slug: string;
		title: string;
		client: ClientType;
	};
	node: ProjectType;
};

const ProjectTemplate = ({ pageContext }: PageProps<{}, PageContext>) => {
	const { previous, node, next } = pageContext;

	return (
		<Layout>
			<ProjectContent project={node} />
			<OtherProjects previous={previous} next={next} />
			<ProjectenIdee />
		</Layout>
	);
};

export const Head = ({pageContext}: HeadProps) => (
	<SEO title={(pageContext as PageContext).node.title}/>
);

export default ProjectTemplate;
