import * as React from "react";

// components
import {Button, Center, Container, Flex, Group, MantineTheme, Stack, Tooltip, createStyles, useMantineTheme} from "@mantine/core";
import { Link } from "gatsby";
import { ClientType } from "../../types";

// icons
import chevronLeftIcon from '../../images/icons/cheveron-left.svg';
import chevronRightIcon from '../../images/icons/cheveron-right.svg';

interface Props {
	previous: {
		slug: string;
		title: string;
		client: ClientType;
	};
	next: {
		slug: string;
		title: string;
		client: ClientType;
	};
}

const useStyles = createStyles((theme:MantineTheme) => ({
		volgende:{
			[theme.fn.smallerThan('md')]:{
				paddingBottom:20,
				fontSize: "calc(24px + 1vw)"
			}
		}
}))

const OtherProjects = ({ previous, next }: Props) => {
	const { classes } = useStyles();
    const theme: MantineTheme = useMantineTheme();
	return (
		<Container py="xl" size="lg">
			<Group
				position="apart">
				<div>
					{previous && (
						<Button
							variant="white"
							color="gray"
							sx={(theme) => ({
								boxShadow: "none",
								"&:hover": {
									boxShadow: "none",
								},
							})}
							component={Link}
							to={`/projecten/${previous.slug}`}
						>
							<Flex className={classes.volgende}>
								<Center>
									<img src={chevronLeftIcon} alt={"bullet"} width={15} />
								</Center>
								<Stack p="sm">
									<Flex direction="column" align="flex-start">
										<Tooltip label={previous.title}>
											<span>Vorige</span>
										</Tooltip>
									</Flex>
								</Stack>
							</Flex>
						</Button>
					)}
				</div>
				<div>
					{next && (
						<Button
							variant="white"
							color="gray"
							sx={(theme) => ({
								boxShadow: "none",
								"&:hover": {
									boxShadow: "none",
								},
							})}
							component={Link}
							to={`/projecten/${next.slug}`}
						>
							<Flex className={classes.volgende}>
								<Stack p="sm">
									<Flex direction="column" align="flex-end">
										<Tooltip label={next.title}>
											<span>Volgende</span>
										</Tooltip>
									</Flex>
								</Stack>
								<Center>
									<img src={chevronRightIcon} alt={"bullet"} width={15} />
								</Center>
							</Flex>
						</Button>
					)}
				</div>
			</Group>
		</Container>
	);
};

export default OtherProjects;
